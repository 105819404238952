import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil, catchError, Subject, debounceTime } from 'rxjs';
import { getUserState } from '../../store/user/selectors/user.selector';
import { NavigationService } from '../navigation/navigation.service';
import { RegistrationForm } from '../../store/sign-up/models/sign-up.model';
import { AccountService } from '../account/account.service';
interface AccessToken {
  data: string;
}
interface UserLogin {
  login: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private navigation: NavigationService,
    private accountService: AccountService
  ) {}
  public loginData: UserLogin = {
    login: '',
    password: '',
  };

  private registrationFormData: RegistrationForm | null = null;
  unsubus$: Subject<boolean> = new Subject();
  userState$: Observable<any> = this.store.select(getUserState);

  blockAuthPages(): Observable<boolean> {
    return new Observable((observer) => {
      this.userState$.pipe(takeUntil(this.unsubus$), debounceTime(200)).subscribe((res: any) => {
        if (res.isAccesToken !== null) {
          if (res?.isAccesToken) {
            this.navigation.navigateTo('/');
            observer.next(false);
          }
          observer.next(true);
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    });
  }
  getIsUserAuth(): Observable<boolean> {
    return new Observable((observer) => {
      this.userState$.pipe(takeUntil(this.unsubus$), debounceTime(200)).subscribe((res: any) => {
        if (!res.isAccesToken) {
          this.navigation.navigateTo('/auth/sign-in');
        }
        observer.next(true);
        this.unsubus$.next(true);
        this.unsubus$.complete();
      });
    });
  }

  login(data: any) {
    return this.http.post('/auth/auth/login', data);
  }

  logout() {
    return this.http
      .post(`/auth/auth/logout`, {})
      .pipe(
        catchError((e) => {
          throw new Error(`Unable to log out: ${e}`);
        })
      )
      .subscribe(() => {
        window.location.href = '/';

        sessionStorage.removeItem('ACCESS_TOKEN');
      });
  }

  getAccessToken(): Observable<AccessToken> {
    return this.http.get<AccessToken>(`/auth/auth/access-token`, {
      withCredentials: true,
    });
  }

  getRefreshToken(token: string) {
    return this.http.post(`/auth/auth/refresh-token`, token, {
      withCredentials: true,
    });
  }

  isAccessValid(): Observable<boolean> {
    return this.http.post<boolean>(`/auth/auth/validate-access`, null, {
      withCredentials: true,
    });
  }

  public checkRegisterParams(params: RegistrationForm) {
    const updatedRegistrationFormData = {
      phone: String(params.phoneCode).replace('+', '') + String(params.phoneNumber),
      email: params.email,
    };

    return this.http.post('auth/auth/unique-fields', updatedRegistrationFormData);
  }

  generateRandomPostalCode() {
    const length: number = Math.floor(Math.random() * 2) + 1;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result[length - 1];
  }

  generateRandomString() {
    const length = Math.floor(Math.random() * 6) + 2;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result;
  }
  register(params: RegistrationForm) {
    return this.http.post('/auth/auth/sign-up', {
      ...params,
      ...{ maskedEmail: params.email },
      ...{ phone: String(params.phoneCode).replace('+', '') + String(params.phoneNumber) },
      ...{
        dateOfBirth: this.accountService.formatDateOfBirthToDate(
          params?.birthDay,
          params?.birthMonth,
          params?.birthYear
        ),
      },
    });
  }
}
